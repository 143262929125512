import * as React from "react";
import { Field } from "formik";

export interface FieldProps {
    name: string,
    placeholder?: string,
    label?: any | string,
    id?: string,
    onBlur?: Function,
    cb?: any,
    contentList?: any,
    options?: any,
    onChange?: Function,
    component?: Function,
    values?: any,
    onSelectChange?: Function,
    InputProps?: any,
    helperText?: any,
    InputLabelProps?:any
}

const TextFieldCommon = (props: FieldProps) => {
    const { name, values } = props;
    return <Field
            value={values ? values[name] : ""}
            {...props}
            autoComplete="off"
            fullWidth
    />;
};


export default TextFieldCommon;
