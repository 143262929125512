
import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { FormHelperText } from '@material-ui/core';


const useStyles = makeStyles(() =>
    createStyles({
        error: {
            color: "#FF1744",
            fontSize:"12px"
        },
    }),
);

type ECProps = {
  errors?: any,
  name: string,
  skipTouched?: boolean,
  style?: any,
  touched?: any,
}

const Error = (props: ECProps) => {
    const classes = useStyles();
    const { errors, name, skipTouched, style = {}, touched } = props;
    const showCondition = errors[ name ] && (skipTouched || touched[ name ]);
    return (
        <>
          {
            showCondition &&
            <FormHelperText style={{margin: '0', fontSize: '12px', ...style}}>
              <span className={classes.error}> {errors[ name ]} </span>
            </FormHelperText>
          }
        </>
    )
};

export default Error;
