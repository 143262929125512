import * as React from "react";

export interface LabelProps {
    required?: boolean
    label?: string
}

const label = ({ label, required }: LabelProps) => {
    return (
        <React.Fragment>
            <label className="mb0">{label} </label>
            {
                required && <span className="requiredHighlight">*</span>
            }
        </React.Fragment>
    );
};
export default label;
