import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Variant } from '@material-ui/core/styles/createTypography';
import { DialogTitle as MuiDialogTitle, DialogTitleProps } from './index';

export interface Props extends DialogTitleProps {
  children: React.ReactNode;
  isColorPrimary?: boolean;
  onClose?: () => void;
  titleVariant?: Variant | 'inherit';
}

const DialogHeader: React.FC<Props> = ({children, onClose, isColorPrimary, titleVariant, ...other}) => {
  const clr = isColorPrimary ? 'primary' : '';
  const _styles = (theme: Theme) => createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 15,
      color: '#000000',
    },
    large: {
      width: theme.spacing(17),
      height: theme.spacing(17),
    },
  });
  const classes: any = makeStyles(_styles)();
  return (
    <MuiDialogTitle disableTypography color={clr} className={classes.root} {...other}>
      <Typography variant={titleVariant || 'h6'}>{children}</Typography>
      {
        !!onClose &&
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    </MuiDialogTitle>
  );
};

export default DialogHeader;
