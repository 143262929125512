import * as React from "react";
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select
} from "@material-ui/core";


export default (props: any) => {
    const { field: { name }, label, className, form: { setFieldValue, values, submitForm }, value, options, onSelectChange, disabled, getValuesOnChange, helperText, renderValue } = props;
    interface oProps {
        id?: string | number
        key?: string | number,
        value: string | number | null | undefined,
        name: string
    }

    return (
        <FormControl
            variant="outlined"
            fullWidth={true}
            className={className || ''}
        >   {
                label &&
                <InputLabel id={name}>{label}</InputLabel>
            }
            <Select
                id={name}
                labelId={name}
                name={name}
                multiple
                value={value}
                className="muiSelect"
                onChange={(e: any) => {
                    setFieldValue(name, e.target.value);
                    onSelectChange(e.target.value, setFieldValue, submitForm);
                    if (typeof getValuesOnChange === 'function') {
                        getValuesOnChange(e.target.value, setFieldValue, values)
                    }
                }}
                label={label}
                disabled={!!disabled}
                renderValue={renderValue}
            >
                {
                    options.length && options.map((o: oProps) => {
                        if (!o.key) {
                            o.key = o.id;
                        }
                        return (
                            <MenuItem key={`${o.key}-${o.name}`} value={o.key} className="mb5">
                                <Checkbox color="primary" checked={value.indexOf(o.key) > -1} />
                                <ListItemText primary={o.name} />
                            </MenuItem>
                        );
                    })
                }
            </Select>
            <span className={'f12'} >
                {helperText}
            </span>
        </FormControl>
    );
};
