import * as React from 'react';
import { Dialog as MuiDialog, DialogProps } from '@material-ui/core';
import { DialogActions, DialogContent, DialogHeader } from './index';
import { Variant } from '@material-ui/core/styles/createTypography';

export { default as DialogContentText } from '@material-ui/core/DialogContentText';

export interface GenericDialogProps extends DialogProps {
  actions?: React.ReactNode | any;
  content: React.ReactNode | any;
  disableSpacing?: boolean;
  dividers?: boolean;
  onClose: any;
  open: boolean;
  title?: string;
  titleVariant?: Variant | 'inherit';
  dialogClassName?:string,
}

const GenericDialog: React.FC<GenericDialogProps> = (props) => {
  const {actions, content,dialogClassName, dividers, onClose, title, titleVariant, ...dialogProps} = props;
  return (
    <>
      <MuiDialog className={dialogClassName} {...dialogProps}>
        {
          title &&
          <DialogHeader onClose={onClose} titleVariant={titleVariant}>
            {title}
          </DialogHeader>
        }
        <DialogContent dividers={dividers}>{content}</DialogContent>
        {
          actions &&
          <DialogActions>{actions}</DialogActions>
        }
      </MuiDialog>
    </>
  );
};

export default GenericDialog;
