
import * as React from "react";
import TextField from '@material-ui/core/TextField';
import InputMask from "react-input-mask";

const PhoneNumberField = (props: object) => {
    return (
      <InputMask mask="(999) 999-9999" {...props}>
        {
          (inputProps: object) => {
            return (<TextField {...inputProps} inputProps={{inputMode: 'numeric'}} type="text" variant="outlined" />)
          }
        }
      </InputMask>
    );
};

export default PhoneNumberField;
