import * as React from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";

interface selectFieldProps {
    field: {
        name: string
    },
    label: string,
    className?: string,
    placeholder:string,
    helperText?:string,
    value: number | string,
    options: [],
    onSelectChange: Function,
    form: {
        setFieldValue: Function,
        values: any,
        submitForm?:Function
    },
    onClose?: Function,
    disabled?: boolean,
    getValuesOnChange?: Function
}

const SelectField = (props: selectFieldProps) => {
    const { field: { name }, label, className, form: { setFieldValue, values, submitForm }, value, options, onSelectChange, onClose, disabled, getValuesOnChange, helperText } = props;
    interface oProps {
        id?: string | number
        key?: string | number,
        value: string | number | null | undefined,
        name: string
    }

    return (
            <FormControl
                    variant="outlined"
                    fullWidth={true}
                    className={className || ''}
            >   {
                label &&
                <InputLabel id={name}>{label}</InputLabel>
            }
                <Select
                        id={name}
                        labelId={name}
                        name={name}
                        value={value}
                        onChange={(e: any) => {
                            setFieldValue(name, e.target.value);
                            onSelectChange(e.target.value, setFieldValue, submitForm);
                            if(typeof getValuesOnChange === 'function'){
                                getValuesOnChange(e.target.value, setFieldValue, values)
                            }
                        }}
                        label={label}
                        onClose={()=> {
                            if(typeof onClose == 'function'){
                                onClose();
                            }
                        }}
                        disabled={disabled ? true : false}
                >
                    {
                        options.length && options.map((o: oProps) => {
                            if (!o.key)
                                {
                                  o.key = o.id;
                                }
                            return (<MenuItem key={`${o.key}-${o.name}`} value={o.key}>
                                {o.name}
                            </MenuItem>);
                        })
                    }
                </Select>
            <span className={'f12'} >
                {helperText}
            </span>
            </FormControl>
    );

};

export default SelectField;
