import React, { useEffect } from 'react';
import { AutocompleteInputChangeReason as _AICReason, default as Autocomplete } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { FormikValues } from './FormWrapper';

interface contentType {
    title: string,
    key: string,
    value?: string,
    name?: string
}

interface autoCompleteProps {
    field: {
        name: string
    },
    label: string,
    setFieldValue: Function,
    cb: Function,
    contentList: any,
    placeholder: string,
    onSelectChange: Function,
    value: contentType,
    form: FormikValues,
    needUpdate?: boolean,
    disabled?: boolean | undefined
    browserSuggestions?: boolean;
    freeSolo?: boolean;
    filterOptions?: any
    getOptionLabel?: any
}

export type AutocompleteInputChangeReason = _AICReason;

const defaultGetOptionLabel = (option: contentType) => option.title ? option.title : "";

function AutoComplete(props: autoCompleteProps) {
    const { field: { name }, label, form: {resetForm, setFieldValue, submitForm}, cb, contentList, placeholder, onSelectChange, needUpdate, disabled , freeSolo = false, filterOptions, getOptionLabel, ...custom } = props;
    const defaultProps = {
        options: contentList,
        getOptionLabel: getOptionLabel || defaultGetOptionLabel
    };
    const [newVal, setValue] = React.useState<contentType | null>(props.value ||null);
    const [initialLoad, setInitialLoad] = React.useState<boolean>(true);
    useEffect(()=>{
        if(props.value && props.value.key && initialLoad) {
            setInitialLoad(false);
            setValue(props.value);
        }
        if(props.value && props.value.key && needUpdate && !initialLoad){
            setValue(props.value);
        }
    },[props.value && props.value.key])

    return (
        <Autocomplete
            {...custom}
            {...defaultProps}
            {...(filterOptions? {filterOptions}: {})}
            id={name}
            autoComplete
            disabled={!!disabled}
            freeSolo={freeSolo}
            onChange={(_event: any, newValue: any) => {
                setValue(newValue)
                setFieldValue(name, newValue ? newValue.key : null);
                onSelectChange && onSelectChange(newValue ? newValue.key : null, setFieldValue, submitForm);
            }}
            onInputChange={(_event: object, value: string, reason: _AICReason) => {
                if (typeof cb === 'function' && reason !== 'reset') {
                    cb(value, setFieldValue, reason);
                }
                if (reason === 'clear') {
                  resetForm();
                }
            }}
            popupIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>}
            includeInputInList
            value={newVal}
            renderInput={(params:any)=>{
                return <TextField {...params} label={label} placeholder={placeholder} name={name} fullWidth variant="outlined"
                />
            }}
        />
    );
}

export default AutoComplete;
