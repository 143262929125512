import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Color } from '@material-ui/lab';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type NiwSnackbarProps = {
  handleClose: () => void;
  message: string;
  open?: boolean;
  severity: Color;
}

export function NiwSnackbar({handleClose, message, open, severity}: NiwSnackbarProps) {
  if (!message) {
    return <></>;
  }

  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      autoHideDuration={6000}
      onClose={handleClose}
      open={open || !!message}
    >
      <Alert style={{ zIndex: 999999 }} onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
