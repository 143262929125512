import React from 'react';
import { Button as MuButton, ButtonProps as MuButtonProps, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

interface _ButtonProps extends MuButtonProps {
  content?: string;
  label?: string;
  loading?: boolean;
}

export type ButtonProps = _ButtonProps;

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: blue[ 500 ],
      position: 'absolute',
      top: '10px',
      left: '50%',
      marginTop: '0px',
      width: '100%'
    },
  })
);

// Need to fix with ButtonProps. For now, I have used 'any'.
export default function Button(btProps: _ButtonProps) {
  const {children, label, loading, ...custom} = btProps;
  const classes = useStyles();
  const _miscProps: any = custom;
  const buttonLabel: any = label || children;
  return (
    <div className={classes.wrapper}>
      <MuButton {..._miscProps}>
        {buttonLabel}
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </MuButton>
    </div>
  );
};
