import * as React from "react";

import {
    Radio,
    RadioGroup as RadioGroups,
    FormControlLabel
} from "@material-ui/core";


const defaultOptions: object[] = [
    {
        txt: "Yes",
        id: "0",
    }, {
        txt: "No",
        id: "1",
    },
];

const RadioGroup = ({
    field,
    form: { touched, errors },
    options = defaultOptions,
    ...props
}: any) => {
    const fieldName = field.name;

    return (
        <React.Fragment>
            <RadioGroups {...field} {...props} name={fieldName}>
                {options.map((option: any, idx: number) => (
                    <FormControlLabel key={`${idx}-${option.txt}`} value={option.val} control={<Radio color={'primary'} />}
                        label={option.txt} />
                ))}
            </RadioGroups>

            {touched[fieldName] && errors[fieldName] && (
                <React.Fragment>{errors[fieldName]}</React.Fragment>
            )}
        </React.Fragment>
    );
};

export default RadioGroup;