import * as React from "react";
import TextField from '@material-ui/core/TextField';
interface FieldProps {
    field: {
        name: string,
        onChange: Function,
    },
    form:{
        setFieldValue: Function,
        values: any
    }
    placeholder: string,
    label: string,
    inputProps?: any,
    value: string,
    helperText?: string,
    cb?: Function
}
const Numeral = (props: FieldProps) => {
    const {cb, field: {name}, form: {setFieldValue, values}, inputProps, label, placeholder, value, ...custom} = props;
    const commonInpProps = {inputMode: 'numeric'};
    const _inputProps = inputProps ? {...commonInpProps, ...inputProps} : commonInpProps;
    return <TextField
        fullWidth
        name={name}
        placeholder={placeholder}
        label={label}
        value={value}
        type="number"
        {...custom}
        inputProps={_inputProps}
        variant="outlined"
        onBlur={(e) => {
            if (typeof cb === "function") {
                cb(e, value ,setFieldValue, values);
            }
        }}
    />;
};

export default Numeral;
